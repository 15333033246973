import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const SuccessPage = () => (
  <Layout>
    <SEO
      title="Success: Email sent."
      keywords={[
        `WTW `,
        `WTW Analytics`,
        `wtwanalytics.de`,
        `wtwanalytics`,
        `WTW de`,
        `Wastewater treatment`,
        `systems lab`,
        `water analysis`,
        ` quality online measurement technology`,
        `water analysis WTW`,
        `Lab laboratory`,
        `lab`,
        `On-Line`,
        `Online Process`,
        `IDS Wireless`,
        `IQSN`,
        `IQ SENSOR NET`,
        `Photometer`,
        `OxiTop`,
        `MultiLine`,
        `inoLab`,
        `photoLab `,
      ]}
    />

    <div
      className="text-center section"
      style={{
        minHeight: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <p className="h1 text-success">
        <i class="fas fa-check-square "></i> <br />
        Success!
      </p>
      <p className="sub-heading">
        Thank you for taking the time to write to me. <br />
        Will get back to you very soon.
      </p>
      Lets take you back <Link to="/">Home</Link>
    </div>
  </Layout>
);

export default SuccessPage;
